import React, { useState, useEffect } from 'react';
import Bb8 from './Bb8';

const footerNavs = [
    {
        href: 'javascript:void()',
        name: 'About'
    },
    {
        href: 'javascript:void()',
        name: 'Blog'
    },
    {
        href: 'javascript:void()',
        name: ''
    },
    {
        href: 'javascript:void()',
        name: 'Team'
    },
    {
        href: 'javascript:void()',
        name: 'Careers'
    },
    {
        href: 'javascript:void()',
        name: 'Support'
    }
];

function Footer() {
    const [darkMode, setDarkMode] = useState(false);

    const handleToggle = () => {
        setDarkMode(!darkMode);
    };

    useEffect(() => {
        if (darkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [darkMode]);

    return (
        <footer className="px-4 py-5 mx-auto md:px-8">
            <div className="mt-8 items-center justify-between sm:flex">
                <div className="mt-4 sm:mt-0">
                    &copy; AJV Development and Design. All rights reserved.
                </div>
                <div className="mt-6 sm:mt-0">
                    {/* Uncomment the following block to include social media links */}
                    {/* <ul className="flex items-center space-x-4">
                        <li className="w-10 h-10 border rounded-full flex items-center justify-center">
                            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                <FaInstagram className="w-6 h-6 text-pink-500" />
                            </a>
                        </li>
                        <li className="w-10 h-10 border rounded-full flex items-center justify-center">
                            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin className="w-6 h-6 text-blue-700" />
                            </a>
                        </li>
                    </ul> */}
                </div>
            </div>
            <style jsx>{`
                .svg-icon path,
                .svg-icon polygon,
                .svg-icon rect {
                    fill: currentColor;
                }
            `}</style>
        </footer>
    );
}

export default Footer;
