import React from 'react';
import Aaliyah from '../Assets/Aaliyah.png';
import Joel from '../Assets/Joel.png';
import { Element } from 'react-scroll';

function Team() {
    const team = [
        {
            avatar: Joel,
            name: "AJ Vazquez",
            title: "Frontend Developer / UI Designer",
            desc: "Hi there! I’m AJ, a passionate Frontend Developer and UI Designer who thrives on transforming ideas into captivating digital experiences. With a knack for blending aesthetics and functionality, I love creating websites that not only look stunning but also provide seamless interactions.",
            linkedin: "javascript:void(0)",
            twitter: "javascript:void(0)",
            github: "javascript:void(0)"
        },
        {
            avatar: Aaliyah,
            name: "Aaliyah Vazquez",
            title: "Graphic Designer / Social Media Manager",
            desc: "I'm Aaliyah. I am a freelance graphic designer, eager to apply my skills and passion for creating impactful visual experiences. With a strong foundation in designing flyers, logos, newsletters, and social media content for local businesses. I ensure to bring their creative visions to life.",
            linkedin: "javascript:void(0)",
            twitter: "javascript:void(0)",
            github: "javascript:void(0)"
        },
        {
            avatar: "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
            name: "Daniel Martin",
            title: "Product Designer",
            desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
            linkedin: "javascript:void(0)",
            twitter: "javascript:void(0)",
            github: "javascript:void(0)"
        },
        {
            avatar: "https://images.unsplash.com/photo-1540569014015-19a7be504e3a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80",
            name: "Vicky Tanson",
            title: "Product Manager",
            desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
            linkedin: "javascript:void(0)",
            twitter: "javascript:void(0)",
            github: "javascript:void(0)"
        },
    ];

    const displayedTeam = team.slice(0, 2);

    return (
        <Element name="Team" id='Team'>
            <section className="py-14">
                <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                    <div className="max-w-xl">
                        <h3 className="font-extrabold text-3xl sm:text-4xl">
                            Meet our talented team
                        </h3>
                    </div>
                    <div className="mt-12">
                        <ul className="grid lg:grid-cols-2">
                            {displayedTeam.map((item, idx) => (
                                <li key={idx} className="gap-8 sm:flex">
                                    <div className="w-full h-60 flex justify-center sm:justify-start my-4">
                                        <img
                                            src={item.avatar}
                                            className="my-2 h-full object-cover object-center shadow-md rounded-xl"
                                            alt={`${item.name}'s avatar`}
                                            onError={(e) => { e.target.src = 'path/to/default/image.png'; }} // Add a default image path here
                                        />
                                    </div>
                                    <div className="mt-4 sm:mt-0">
                                        <h4 className="text-2xl font-extrabold">{item.name}</h4>
                                        <p className="text-indigo-600 text-xl font-bold">{item.title}</p>
                                        <p className="mt-2">{item.desc}</p>
                                        <div className="mt-3 flex gap-4 text-gray-400">
                                            {item.twitter && (
                                                <a href={item.twitter}>
                                                    <svg className="w-5 h-5 duration-150 hover:text-gray-500" fill="currentColor" viewBox="0 0 48 48">
                                                        {/* Twitter SVG Path Here */}
                                                    </svg>
                                                </a>
                                            )}
                                            {item.github && (
                                                <a href={item.github}>
                                                    <svg className="w-5 h-5 duration-150 hover:text-gray-500" fill="currentColor" viewBox="0 0 48 48">
                                                        {/* GitHub SVG Path Here */}
                                                    </svg>
                                                </a>
                                            )}
                                            {item.linkedin && (
                                                <a href={item.linkedin}>
                                                    <svg className="w-5 h-5 duration-150 hover:text-gray-500" fill="none" viewBox="0 0 48 48">
                                                        {/* LinkedIn SVG Path Here */}
                                                    </svg>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        </Element>
    );
}

export default Team;
