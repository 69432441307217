import React from 'react';
import { Element } from 'react-scroll';

const plans = [
    {
        name: "One and Done",
        desc: "Want to get a website, but don't want to do constant updates? We got you.",
        contactText: "$2300",
        isMostPop: false,
        features: [
            "3 Static Pages (+$150 per page after)",
            "Contact Form",
            "2 Free Edits ($50 per edit after)"
        ],
    },
    {
        name: "Subscription",
        desc: "Want a website and looking to add content regularly? This is for you.",
        contactText: "$350 /mo",
        isMostPop: true,
        features: [
            "3 months down upfront (1 Year Contract)",
            "Unlimited Edits",
            "Free Updates",
            "3 Static Pages (+$125 per page after)",
            "Contact Form",
            "SEO Optimization",
            "Less upfront cost and more flexibility", 
        ],
    },
    {
        name: "Brand Package",
        desc: "You want it all, we got it all. Includes company social media management and more.",
        contactText: "Contact us for pricing",
        isMostPop: false,
        features: [
            "3 Static Pages (+$100 per page after)",
            "Contact Form",
            "3 Free Edits ($50 per edit after)",
            "Custom Logo and Brand Design",
            "Social Media Management",
            "Business Cards and Ad Management",
            "....and much more!",
        ],
    },
];

function Pricing() {
    return (
        <Element name="Pricing">
            <section className='py-14 relative'>
                <div className="max-w-screen-xl mx-auto px-4 text-white md:px-8">
                    <div className='relative max-w-xl mx-auto sm:text-center'>
                        <h3 className='text-white text-3xl font-extrabold sm:text-4xl'>
                            Pricing for all sizes
                        </h3>
                        <div className='mt-3 max-w-xl'>
                            <p>
                                We offer competitive pricing for all sizes of businesses. Just started out? No problem, we got you. Already an established company looking for the next step? We can take care of you. Choose the plan that suits you best.
                            </p>
                        </div>
                    </div>
                    <div className='mt-16 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3'>
                        {
                            plans.map((item, idx) => (
                                <div key={idx} className={`relative flex-1 flex items-stretch flex-col rounded-xl border-2 mt-6 sm:mt-0 ${item.isMostPop ? "mt-10" : ""}`}>
                                    {
                                        item.isMostPop && (
                                            <div className="w-32 absolute -top-5 left-0 right-0 mx-auto px-3 py-2 rounded-full border shadow-md bg-white text-center text-indigo-700 text-sm font-extrabold">
                                                MOST POPULAR
                                            </div>
                                        )
                                    }
                                    <div className="p-8 space-y-4 border-b">
                                        <span className='text-white'>
                                            {item.name}
                                        </span>
                                        <div className='text-white text-3xl font-semibold'>
                                            {item.contactText ? item.contactText : `$${item.price}`}
                                        </div>
                                        <p>
                                            {item.desc}
                                        </p>
                                    </div>
                                    <ul className='p-8 space-y-3'>
                                        <li className="pb-2 text-white font-medium">
                                            <p>Features</p>
                                        </li>
                                        {
                                            item.features.map((featureItem, idx) => (
                                                <li key={idx} className='flex items-center gap-5'>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        className='h-5 w-5 text-indigo-600'
                                                        viewBox='0 0 20 20'
                                                        fill='currentColor'>
                                                        <path
                                                            fillRule='evenodd'
                                                            d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                                                            clipRule='evenodd'></path>
                                                    </svg>
                                                    {featureItem}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </Element>
    );
}

export default Pricing;
