import React from 'react';
import Computer from '../Assets/Computer.jpg';

function Cta() {
  return (
    <section className="py-14" aria-labelledby="cta-heading">
      <div className="max-w-screen-xl mx-auto md:px-8 ">
        <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex py-4">
          {/* Image with lazy loading */}
          <div className="flex-1 sm:hidden lg:block">
            <img src={Computer} className="md:max-w-lg sm:rounded-lg" alt="Computer" loading="lazy" />
          </div>

          {/* Call-to-Action Text */}
          <div className="max-w-xl px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
            <h2 id="cta-heading" className="text-primary text-3xl font-extrabold sm:text-4xl">
              Crafting Digital Experiences with Creativity and Code
            </h2>
            <p className="mt-3 text-text-secondary">
              Making a unique website is crucial for small businesses. It can be the biggest step toward growth, 
              and that's where we come in. We specialize in creating visually appealing, highly functional websites 
              and applications that leave a lasting impression.
            </p>
            {/* You could also consider adding a CTA button here to guide users */}
          </div>
        </div>
        
        {/* Background Blur */}
        <div
          className='absolute inset-0 blur-[105px] max-w-lg h-[800px] mx-auto sm:max-w-3xl sm:h-[400px] z-[-1]'
          style={{
            background: "linear-gradient(106.89deg, rgba(192, 132, 252, 0.11) 15.73%, rgba(14, 165, 233, 0.41) 15.74%, rgba(232, 121, 249, 0.26) 56.49%, rgba(79, 70, 229, 0.4) 115.91%)",
          }}
        />
      </div>
    </section>
  );
}

export default Cta;
