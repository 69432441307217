import React, { useRef, useState } from 'react';
import { Element } from 'react-scroll';
import * as Dialog from "@radix-ui/react-dialog";
import emailjs from '@emailjs/browser';
import Design from '../Assets/Ajv.jpg';

function Contact() {
  const form = useRef();
  const [validationErrors, setValidationErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false); // State to control the Dialog

  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[A-Za-z]+(?: [A-Za-z]+)?$/;
    const phoneRegex = /^(\+?\d{1,4}[-\s]?)?(\(?\d{3}\)?[-\s]?)?\d{3}[-\s]?\d{4}$/;

    if (!form.current.user_email.value.trim() || !emailRegex.test(form.current.user_email.value.trim())) {
      errors.email = 'Please enter a valid email address';
    }

    if (!form.current.user_number.value.trim() || !phoneRegex.test(form.current.user_number.value.trim())) {
      errors.number = 'Please enter a valid phone number';
    }

    if (!form.current.user_description.value.trim()) {
      errors.description = 'Please describe the work needed';
    }

    if (!form.current.user_name.value.trim() || !nameRegex.test(form.current.user_name.value.trim())) {
      errors.name = 'Please enter a valid name';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    emailjs.sendForm('service_uhhteyn', 'template_rtusz2v', form.current, 'TmMEmjkXumbJoGh4r')
      .then((res) => {
        form.current.reset();
        setDialogOpen(true); // Open the Dialog on success
      })
      .catch((error) => {
        alert('Failed to send email! Please ensure all information is entered correctly!');
      });
  };

  return (
    <Element name="Contact" id='Contact'>
      <main className="relative py-10 px-4">
        <div className="relative z-10 max-w-screen-xl mx-auto text-gray-600 sm:px-4 md:px-8">
          <div className="max-w-lg space-y-3 px-4 sm:mx-auto sm:text-center sm:px-0">
            <p className="text-white text-3xl font-semibold sm:text-4xl">
              Need a custom plan? Let's talk.
            </p>
            <p className="text-gray-300">
              We’d love to hear from you! Please fill out the form below.
            </p>
          </div>
          <div className="mt-12 mx-auto px-4 p-8 bg-white sm:max-w-lg sm:px-8 sm:rounded-xl">
            <form ref={form} onSubmit={sendEmail} className="space-y-5">
              <div>
                <label className='font-medium'>
                  Full name
                </label>
                <input
                  type="text"
                  required
                  name='user_name'
                  placeholder='Your Name'
                  className={`w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-gray-800 shadow-sm rounded-lg ${validationErrors.name && 'border-red-500'}`}
                />
                {validationErrors.name && <p className="text-red-500 text-sm">{validationErrors.name}</p>}
              </div>
              <div>
                <label className="font-medium">
                  Email
                </label>
                <input
                  type="email"
                  required
                  name='user_email'
                  placeholder='Enter Email'
                  className={`w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-gray-800 shadow-sm rounded-lg ${validationErrors.email && 'border-red-500'}`}
                />
                {validationErrors.email && <p className="text-red-500 text-sm">{validationErrors.email}</p>}
              </div>
              <div>
                <label className="font-medium">
                  Phone number
                </label>
                <input
                  type="tel"
                  name='user_number'
                  placeholder="+1 (555) 000-000"
                  required
                  className={`w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-gray-800 shadow-sm rounded-lg ${validationErrors.number && 'border-red-500'}`}
                />
                {validationErrors.number && <p className="text-red-500 text-sm">{validationErrors.number}</p>}
              </div>
              <div>
                <label className="font-medium">
                  Message
                </label>
                <textarea
                  name='user_description'
                  required
                  placeholder='Describe the work needed'
                  className={`w-full mt-2 h-36 px-3 py-2 resize-none appearance-none bg-transparent outline-none border focus:border-gray-800 shadow-sm rounded-lg ${validationErrors.description && 'border-red-500'}`}
                ></textarea>
                {validationErrors.description && <p className="text-red-500 text-sm">{validationErrors.description}</p>}
              </div>
              <input
                type='submit'
                value='Submit'
                className="w-full px-4 py-2 text-white font-medium bg-[#343658fd] hover:bg-indigo-400 active:bg-gray-900 rounded-lg duration-150"
              />
            </form>
          </div>
        </div>
      </main>

      {/* Radix UI Dialog */}
      <Dialog.Root open={dialogOpen} onOpenChange={setDialogOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4">
            <div className="bg-white rounded-md shadow-lg px-4 py-6">
              <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 text-green-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <Dialog.Title className="text-2xl font-extrabold text-gray-800 text-center mt-3">
                Successfully sent! Looking forward to working with you.
              </Dialog.Title>
              <img src={Design} alt="Logo" className=" w-36 h-36 mx-auto mt-4" />
              <div className="items-center gap-2 mt-3 text-sm sm:flex">
                <Dialog.Close asChild>
                  <button
                    onClick={() => window.location.reload()}
                    className="w-full mt-2 p-2.5 flex-1 font-bold text-white bg-[#343658fd] rounded-md outline-none ring-offset-2 ring-indigo-600 focus:ring-2">
                    Home
                  </button>
                </Dialog.Close>
                <Dialog.Close asChild>
                  <button
                    className="w-full mt-2 p-2.5 flex-1 font-bold text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                    aria-label="Close"
                  >
                    Close
                  </button>
                </Dialog.Close>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </Element>
  );
}

export default Contact;
