import React from 'react';
import { Link } from 'react-router-dom';
import CMI from '../Assets/cmi.jpg';

function Testimonial() {
  return (
    <section className="py-14 relative">
      <div className="max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="max-w-3xl mx-auto">
          <figure className="text-center">
            <blockquote>
              <p className="text-text-primary text-xl font-semibold sm:text-2xl">
                “Process was professional and easy. Really happy with how the end product came out!“
              </p>
            </blockquote>
            <figcaption className="flex justify-center items-center gap-x-4 mt-6">
              <img
                src={CMI}
                alt="Clean Metro Inc"
                className="w-16 h-16 rounded-full"
              />
              <div>
                <span className="block text-text-primary font-semibold">Clean Metro Inc</span>
                <Link
                  to="http://www.cleanmetro.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-accent text-sm mt-0.5 hover:text-indigo-400"
                >
                  Cleanmetro.com
                </Link>
              </div>
            </figcaption>
          </figure>
        </div>
        <div
          className="absolute blur-[118px]"
          style={{
            background: "linear-gradient(106.89deg, rgba(192, 132, 252, 0.11) 15.73%, rgba(14, 165, 233, 0.41) 15.74%, rgba(232, 121, 249, 0.26) 56.49%, rgba(79, 70, 229, 0.4) 115.91%)",
            width: '100%',
            height: '100%',
          }}
        ></div>
      </div>
    </section>
  );
}

export default Testimonial;
