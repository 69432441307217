import React, { useState, useEffect } from 'react';
import './App.css';
import './index.css';
import Navbar from './Components/Navbar';
import Hero from './Components/Hero';
import Content from './Components/Content';
import Traits from './Components/Traits';
import Contact from './Components/Contact';
import Pricing from './Components/Pricing';
import CTA from './Components/Cta';
import Testimonial from './Components/Testimonial';
import Footer from './Components/Footer';
import Team from './Components/Team';
import ScrollUp from './Components/ScrollUp';
import AnimatedCursor from 'react-animated-cursor';
import { Fade } from 'react-awesome-reveal';
import { isMobile } from './helpers/isDevice';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Load the saved theme from local storage
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setIsDarkMode(savedTheme === 'dark');
    }
  }, []);

  useEffect(() => {
    // Save the theme to local storage
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    document.body.className = isDarkMode ? 'dark' : 'light';
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  return (
    <div className={`changa-style ${isDarkMode ? 'dark' : ''}`}>
      {!isMobile() && (
        <AnimatedCursor
          className='animated-cursor'
          innerSize={12}
          outerSize={32}
          color='255, 255, 255'
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={2}
          trailingSpeed={20}
          outerStyle={{
            mixBlendMode: 'exclusion',
          }}
          clickables={[
            'a',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'label[for]',
            'select',
            'textarea',
            'button',
            '.link'
          ]}
        />
      )}
     
      <Navbar toggleDarkMode={toggleDarkMode} />
      
      <Fade delay= {500} damping={0.9} triggerOnce>
      <div className='shadow-2xl mb-4 p-4 mx-4 rounded-lg backdrop-blur-lg'>
     
      <Hero />
      
      <CTA />
      </div>
      </Fade>
      <Content />
      <Fade delay= {500} damping={0.9} triggerOnce>
      <div className='shadow-2xl mt-4 p-4 mx-4 rounded-lg'>
      
      <Traits />
      <Pricing />
      <Testimonial />
      
      </div>
      <Contact />
      <Team/>
      <Footer />
      </Fade>
      <ScrollUp />
      
    </div>
  );
}

export default App;
