import React from 'react';
import Hamster from '../Components/Hamster';
import { Fade } from 'react-awesome-reveal';

function Content() {
  return (
    <section className="bg-background-light dark:bg-background-dark py-8">
      <div className="items-center px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:px-6">
        <div className="font-light text-text-secondary sm:text-lg">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-text-primary">
            We didn't reinvent the wheel
          </h2>
          <p className="mb-4">
            We are strategists, designers, and developers. Innovators and problem solvers. Small enough to be simple and quick, but big enough to deliver the scope you want at the pace you need. 
          </p>
          <p>
            So many companies are stuck in the past, but we are here to help you move forward. We are here to help you grow. There are historically proven methods that have shown results, and we apply them. That is why we use modern technologies, tools and approaches to help companies stay competitive and attractive.
          </p>
        </div>
        <Fade direction="right" >
          <div className="lg:p-2">
            <Hamster />
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default Content;
