import React from 'react';
import { Element } from 'react-scroll';
import { FaLaptopCode } from 'react-icons/fa';  // Web Design
import { MdBrush } from 'react-icons/md';        // Graphic Design
import { FaShareAlt } from 'react-icons/fa';    // Social Media Management

const features = [
    {
        icon: <FaLaptopCode className="w-6 h-6 text-accent" />,
        title: "Web Design",
        desc: "Craft visually stunning and responsive websites tailored to your brand's unique identity. From user-friendly navigation to seamless mobile experiences, we focus on designs that not only look great but also engage your audience."
    },
    {
        icon: <MdBrush className="w-6 h-6 text-accent" />,
        title: "Graphic Design",
        desc: "Bring your brand to life with eye-catching graphics that resonate. From logos and brochures to digital artwork, we create visually compelling designs that communicate your message effectively."
    },
    {
        icon: <FaShareAlt className="w-6 h-6 text-accent" />,
        title: "Social Media Management",
        desc: "Maximize your online presence with strategic social media management. We help you build and engage a community through tailored content, timely posts, and impactful campaigns on major platforms."
    }
];

function Traits() {
    return (
        <Element name="Features" id="Features">
            <section className="pt-8 pb-14 relative">
                <div className="max-w-screen-xl mx-auto px-4 text-text-secondary md:px-8">
                    <div className="max-w-xl space-y-3">
                        <p className="text-text-primary text-3xl font-extrabold sm:text-4xl">
                            Straight Forward. Less Hassle.
                        </p>
                        <p>
                            What can we do for you? Let’s take a look at some of the features we offer.
                        </p>
                    </div>
                    <div className="mt-12">
                        <ul className="grid gap-x-12 divide-y [&>.feature-1]:pl-0 sm:grid-cols-2 sm:gap-y-8 sm:divide-y-0 lg:divide-x lg:grid-cols-3 lg:gap-x-0">
                            {features.map((item, idx) => (
                                <li key={idx} className={`feature-${idx + 1} space-y-3 py-8 lg:px-12 sm:py-0`}>
                                    <div className="w-12 h-12 border text-accent rounded-full flex items-center justify-center">
                                        {item.icon}
                                    </div>
                                    <h4 className="text-lg text-text-primary font-extrabold">
                                        {item.title}
                                    </h4>
                                    <p>
                                        {item.desc}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div
                    className="absolute inset-0 blur-[118px] max-w-full h-[800px] mx-auto sm:h-[400px] z-[-1]"
                    style={{
                        background: "linear-gradient(106.89deg, rgba(192, 132, 252, 0.11) 15.73%, rgba(14, 165, 233, 0.41) 15.74%, rgba(232, 121, 249, 0.26) 56.49%, rgba(79, 70, 229, 0.4) 115.91%)",
                    }}
                ></div>
            </section>
        </Element>
    );
}

export default Traits;
