import React from 'react';
import '../ExtraStyle/Bb8.css'; // Ensure you have the corresponding CSS for styling

function Bb8({ onToggle, isChecked }) {
  const handleChange = (event) => {
    console.log('Checkbox changed:', event.target.checked);
    onToggle(); // Trigger the toggle function from parent
  };

  return (
    <div className="bb8-container">
      <label className="bb8-toggle">
        <input
          className="bb8-toggle__checkbox"
          type="checkbox"
          checked={isChecked} // Reflects the current state
          onChange={handleChange}
        />
        <div className="bb8-toggle__container">
          <div className="bb8-toggle__scenery">
            <div className="bb8-toggle__star"></div>
            <div className="bb8-toggle__star"></div>
            <div className="bb8-toggle__star"></div>
            <div className="bb8-toggle__star"></div>
            <div className="bb8-toggle__star"></div>
            <div className="bb8-toggle__star"></div>
            <div className="bb8-toggle__star"></div>
            <div className="tatto-1"></div>
            <div className="tatto-2"></div>
            <div className="gomrassen"></div>
            <div className="hermes"></div>
            <div className="chenini"></div>
            <div className="bb8-toggle__cloud"></div>
            <div className="bb8-toggle__cloud"></div>
            <div className="bb8-toggle__cloud"></div>
          </div>
          <div className="bb8">
            <div className="bb8__head-container">
              <div className="bb8__antenna"></div>
              <div className="bb8__antenna"></div>
              <div className="bb8__head"></div>
            </div>
            <div className="bb8__body"></div>
          </div>
          <div className="artificial__hidden">
            <div className="bb8__shadow"></div>
          </div>
        </div>
      </label>
    </div>
  );
}

export default Bb8;
