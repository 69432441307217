import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { FaArrowUp } from 'react-icons/fa'; // Using FontAwesome for the arrow icon

const ScrollUp= () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) { // Show button when scrolled down 300px
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={`fixed bottom-4 right-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}>
      <Link
        to="Nav" // or use an empty string or any other ID for smooth scrolling to top
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        className="bg-primary text-white p-3 rounded-full shadow-lg hover:bg-primary-dark cursor-pointer "
      >
        <FaArrowUp size={24} />
      </Link>
    </div>
  );
};

export default ScrollUp;
