import React from 'react';
import { Fade } from 'react-awesome-reveal';
import '../ExtraStyle/Among.css';
import '../ExtraStyle/Spiderman.css';
import { Element } from 'react-scroll';

function Hero() {
  return (
    <div id='Hero' className="mt-8 font-link flex justify-center items-center">
      <Fade damping={0.6} triggerOnce>
        <div className="text-center lg:px-12">
          <h1 className="mb-8 text-4xl font-extrabold tracking-tight leading-none text-text-primary dark:text-text-light md:text-5xl lg:text-6xl">
            AJV Web Development and Design
          </h1>
          <p className="mb-8 font-normal text-text-secondary lg:text-2xl sm:px-16 xl:px-48">
            We handle all your Website and Graphic Design Needs.
          </p>
        </div>

        {/* Consider lazy-loading images in this section if any */}

        {/* Uncomment the button below if needed */}
        {/* 
        <div className="flex justify-center items-center py-4">
          <button className="button-among bg-primary text-text-light hover:bg-primary-hover">
            <span className="now">NOW</span>
            <span className="play">View Our Portfolio</span>
          </button>
        </div>
        */}
      </Fade>
    </div>
  );
}

export default Hero;
