import React, { Suspense } from 'react';
import Design from '../Assets/Ajv.jpg'; // Using the original JPG logo
import { Link } from 'react-scroll';

// Lazy load Bb8 component
const Bb8 = React.lazy(() => import('./Bb8'));

function Navbar({ toggleDarkMode, isDarkMode }) {

  const navigation = [
    { title: "TEAM", path: "Team" },
    { title: "PRICING", path: "Pricing" },
    { title: "FEATURES", path: "Features" },
    { title: "CONTACT US", path: "Contact" }
  ];

  return (
    <header>
      <nav id="Nav" className="px-4 lg:px-6 py-2.5">
        <div className="flex justify-between items-center max-w-screen-xl mx-auto">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              {/* Optimized image (still using JPG) */}
              <img
                src={Design}
                className="h-16 sm:h-28 lg:h-28 rounded-2xl"
                alt="AJV Web Development Logo"
              />
            </Link>
          </div>

          {/* Navigation Menu for desktop */}
          <div className="hidden lg:flex flex-grow justify-center items-center">
            <ul className="flex space-x-8 font-extrabold">
              {navigation.map((item, idx) => (
                <li key={idx}>
                  {/* Added anchor fallback for SEO and JS fallback */}
                  <a href={`#${item.path}`} className="lg:hidden">
                    {item.title}
                  </a>
                  <Link
                    to={item.path}
                    smooth={true}
                    duration={1000}
                    className="block py-2 pr-4 pl-3 lg:text-primary-700 lg:p-0 hover:shadow-xl hover:text-indigo-400"
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Mobile Navigation Menu */}
          <div className="lg:hidden flex justify-center items-center">
            <ul className="flex space-x-8 ml-2">
              {navigation.slice(0, 2).map((item, idx) => (
                <li key={idx}>
                  <a href={`#${item.path}`} className="hover:text-indigo-400">
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Mobile Menu Button */}
          <div className="lg:hidden flex items-center mt-4">
            <Suspense fallback={<div>Loading...</div>}>
              <Bb8 onToggle={toggleDarkMode} isChecked={isDarkMode} />
            </Suspense>
          </div>

          {/* BB8 Component for desktop */}
          <div className="hidden lg:flex items-center">
            <Suspense fallback={<div>Loading...</div>}>
              <Bb8 onToggle={toggleDarkMode} isChecked={isDarkMode} />
            </Suspense>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
