// helpers/isDevice.js

// Function to check if the device is a mobile
export function isMobile() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }
  
  // Function to check if the device is a tablet
  export function isTablet() {
    return /Tablet|iPad/i.test(navigator.userAgent);
  }
  
  // Function to check if the device is a desktop
  export function isDesktop() {
    return !isMobile() && !isTablet();
  }